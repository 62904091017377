/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Messages */
import {
  SEO, Container, Section, Article, Heading,
} from '../components/components';

/* Messages */
import * as messages from '../messages/polityka-prywatnosci.messages';

const PolitykaPrywatnosci = () => (
  <>
    <SEO title={messages.mainHeader} />
    <Section>
      <Container subPage>
        <Heading headingLevel="h1" title={messages.mainHeader} highlightedLastWord />
        <Article>
          {messages.content.map(({ paragraph }) => (
            <Markdown
              key={paragraph}
              rehypePlugins={[rehypeRaw]}
              children={paragraph}
            />
          ))}
        </Article>
      </Container>
    </Section>
  </>

);

export default PolitykaPrywatnosci;
